<template>
  <div class="product">
    <div class="productBanner">
      <div class="left">
        <img class="wow fadeInLeft" src="../../assets/img/product/-2-min.png" alt="" />
      </div>
      <div class="right">
        <p class="title p24">使用TRADEWISE CRM</p>
        <p class="title p24">提高管理效率和</p>
        <p class="title p24">提升生产力</p>
        <div class="btn" @click="toDemo">
          <p class="p22">
            <!-- 系统演示 -->
            <a href="http://demo.tradewisesoftware.com" target="_blank" rel="noopener noreferrer">系统演示</a>
            </p>
        </div>
      </div>
    </div>
    <div class="characteristic">
      <div class="WebMain">
        <div class="img">
          <img class="wow fadeInLeft" src="../../assets/img/product/Pad landscape 1-min.png" alt="" />
          <div class="item1 wow fadeInLeft">
            <div class="left">
              <img
                v-show="$store.state.style === 'black'"
                src="../../assets/img/product/icon (1).png"
                alt=""
              />
              <img
                v-show="$store.state.style === 'white'"
                src="../../assets/img/product/icon1 (1).png"
                alt=""
              />
              <p class="p18">数据驱动为核心</p>
            </div>
            <div class="right">
              <img
                v-show="$store.state.style === 'black'"
                src="../../assets/img/product/icon (2).png"
                alt=""
              />

              <img
                v-show="$store.state.style === 'white'"
                src="../../assets/img/product/icon1 (2).png"
                alt=""
              />
              <p class="p18">关注所有用户体验</p>
              <!-- <p class="p18"></p> -->
            </div>
          </div>
          <div class="item2 wow fadeInLeft">
            <div class="left">
              <img
                v-show="$store.state.style === 'black'"
                src="../../assets/img/product/icon (3).png"
                alt=""
              />

              <img
                v-show="$store.state.style === 'white'"
                src="../../assets/img/product/icon1 (3).png"
                alt=""
              />
              <p class="p18">
                提高运营效率降低成本
              </p>
            </div>
            <div class="right">
              <img
                v-show="$store.state.style === 'black'"
                src="../../assets/img/product/icon (4).png"
                alt=""
              />
              <img
                v-show="$store.state.style === 'white'"
                src="../../assets/img/product/icon1 (4).png"
                alt=""
              />

              <p class="p18">多渠道融合管理</p>
            </div>
          </div>
          <div class="item3 wow fadeInLeft">
            <div class="left">
              <img
                v-show="$store.state.style === 'black'"
                src="../../assets/img/product/icon (5).png"
                alt=""
              />
              <img
                v-show="$store.state.style === 'white'"
                src="../../assets/img/product/icon1 (5).png"
                alt=""
              />

              <p class="p18">加强企业内部信息化</p>
            </div>
            <div class="right">
              <img
                v-show="$store.state.style === 'black'"
                src="../../assets/img/product/icon (6).png"
                alt=""
              />
              <img
                v-show="$store.state.style === 'white'"
                src="../../assets/img/product/icon1 (6).png"
                alt=""
              />

              <p class="p18">以数据分析支持决策</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="centerContent"></div>
    <div class="explain">
      <div class="WebMain">
        <div class="item wow fadeInLeft" v-for="item in productList" :key="item.id">
          <div class="top">
            <div class="inner">
              <p class="num p30">0{{ item.id }}</p>
              <p class="title p20">{{ item.title }}</p>
            </div>
          </div>
          <div class="all">
            <div class="inner">
              <p class="num p30">0{{ item.id }}</p>
              <p class="title p20">{{ item.title }}</p>
              <p class="text p14">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productList: [
        {
          id: 1,
          title: "客户关系管理方案",
          img: "产品-1-min.png",
          text: "我们的CRM系统可以帮助您更好地管理客户关系，并提高销售效率和客户满意度。它包括客户分类、客户资料管理、客户来源、KYC审查等功能，使您可以更便捷、更全面地了解每个客户的需求和意愿，更好地服务客户。"
        },
        {
          id: 2,
          title: "跟踪与激励介绍经纪商",
          img: "产品-2-min.png",
          text: "我们的CRM系统为您的IB提供了专属的追踪代码和链接，实现自动计算佣金并确保支付准确、可见和灵活。我们的基于数据驱动的佣金、奖励和激励策略将激励您的IB，提升销售增长。",
        },
        {
          id: 3,
          title: "高效的内部管理",
          img: "产品-3-min.png",
          text: "更清晰地了解公司内部运作状况，有效管理部门和员工。您可以通过此功能快速查看资金数据、交易数据、佣金数据等关键信息，从而快速做出决策，优化企业运营。",
        },
        {
          id: 4,
          title: "清晰的资金管理",
          img: "产品-4-min.png",
          text: "实时掌握资金状况，如入金/出金状况、资金余额、总净入金量等，分层级分时间统计与可视化的展现，让您对业务情况一目了然。 ",
        },
      ],
    };
  },
  methods: {
    toDemo() {
      window.href = 'http://demo.tradewisesoftware.com'
    }
  }
};
</script>

<style lang="scss" scoped>
.product {
  .productBanner {
    // background: url("../../assets/img/product/背景.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 182px;
    padding-bottom: 128px;
    .left {
      padding:0 80px;
      img {
        height: 590px;
        // width: 62%;
      }
    }
    .right {
      // width: 343px;
      text-align: right;
      // color: #fff;
      .title {
        font-weight: bold;
        line-height: 50px;
        width: 100%;
      }
      .btn {
        width: 200px;
        height: 50px;
        background: var(--custom-color);
        border-radius: 8px;
        line-height: 50px;

        margin-top: 36px;
        margin-left: 180px;
        p {
          font-weight: bold;
          text-align: center;
          // line-height: 50px;
          // line-height: 30px;
          // a{
          //     line-height: 54px;
          //   }
        }
      }
    }
  }
  .characteristic {
    padding-top: 118px;
    padding-bottom: 151px;
    .WebMain {
      // text-align: center;

      .img {
        position: relative;
        width: 777px;
        margin: auto;
        img {
          width: 100%;
        }

        .item1,
        .item2,
        .item3 {
          position: absolute;
          display: flex;
          justify-content: space-between;
          img {
            height: 80px;
            width: 80px;
            margin-bottom: 23px;
          }
          p {
            line-height: 25px;
          }
          .left {
            p {
              text-align: left;
            }
          }
          .right {
            p {
              text-align: right;
            }
          }
        }
        .item1 {
          top: -8px;
          width: 936px;
          right: -131px;
          .left,
          .right {
            width: 184px;
            text-align: center;
          }
        }
        .item2 {
          width: 1328px;
          top: 258px;
          right: -240px;
          .left,
          .right {
            width: 240px;
            text-align: center;
          }
          .right {
            width: 160px;
          }
        }
        .item3 {
          width: 990px;
          bottom: -40px;
          right: -49px;
          .left,
          .right {
            width: 240px;
            text-align: center;
          }
        }
      }
    }
  }
  .centerContent {
    width: 100%;
    height: 90px;
    
// background: #2B2D33;
  }
  .explain {
    padding-bottom: 40px;
    // overflow: hidden;
    .WebMain {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        box-sizing: border-box;
        width: 300px;
        height: 540px;
        position: relative;

        &:first-child {
          .all {
            // background-color: #2B2D33;
            
background-color: #2B2D33;
            background: url("../../assets/img/product/产品-1-min.png");

            background-size: 100% 100%;
            background-position: 0 548px;
            background-repeat: no-repeat;
          }
        }
        &:nth-child(2) {
          .all {
            background-color: #2B2D33;
            background: url("../../assets/img/product/产品-2-min.png");
            background-size: 100% 100%;
            background-position: 0 548px;
            background-repeat: no-repeat;
          }
        }
        &:nth-child(3) {
          .all {
            background-color: #2B2D33;
            background: url("../../assets/img/product/产品-3-min.png");
            background-size: 100% 100%;
            background-position: 0 548px;
            background-repeat: no-repeat;
          }
        }
        &:nth-child(4) {
          .all {
            background-color: #2B2D33;
            background: url("../../assets/img/product/产品-4-min.png");
            background-size: 100% 100%;
            background-position: 0 548px;
            background-repeat: no-repeat;
          }
        }
        .inner {
          padding: 40px;
          // background-color: #2B2D33;
          // padding-top: 356px;
        }
        .all {
          height: 548px;
          width: 100%;
          overflow: hidden;
          position: absolute;
          top: -48px;
          .inner {
            position: absolute;
            top: 548px;
            color: white;
          }
          .num {
            border-left: 2px solid var(--custom-color);
          }
          // background-color: #2B2D33;
          // .inner{
          //   padding-top: 157px;
          // }
        }
        .top {
          padding-top: 356px;
          // display: none;
        }

        .num {
          font-weight: 600;
          font-family: Arial-Black, Arial;
          padding-left: 25px;
          
          // border-left: 2px solid #ffffff;
        }
        .title {
          width: 200px;
          font-weight: bold;
          // color: #ffffff;
          line-height: 26px;
          margin-top: 20px;
          margin-bottom: 15px;
        }
        .text {
          font-family: ArialHebrew;
          // color: #ffffff;
          line-height: 20px;
        }
        &:hover {
          .top {
            opacity: 0;
            transition: all 0.1s;
          }
          .all {
            background-color: #2B2D33;
            transition: all 0.8s;
            // position: absolute;
            // top:-48px;
            background-position: 0 0;
            .inner {
              transition: all 0.5s;
              position: absolute;
              top: 48px;
              .num{
                border-left: 2px solid var(--custom-color);
              }
            }
          }
        }
        // &:first-child{
        //   .top {
        //     opacity: 0;
        //   }
        //   .all {
        //     background-color: #2B2D33;
        //     // position: absolute;
        //     // top:-48px;
        //     background-position: 0 0;
        //     .inner {
        //       position: absolute;
        //       top: 48px;
        //     }
        //   }
        // }
      }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 1700px) {
  .product .productBanner .left img {
    width: 800px;
    height: auto;
  }
  .product .explain .WebMain .item{
    margin-top: 30px;
  }
  
}
@media screen and (min-width: 800px) and (max-width: 1250px){
  .product .characteristic .WebMain .img {
      width: 100%;
      .item1, .item2, .item3{
        position: relative;
        // display: flex;
        width: 100%;
        right: 0;
        top: 0;
        margin-top: 20px;
        .left, .right{
          width: 50%;
        }
        p{
          text-align: center !important;
        }
      }
    }
}

@media screen and (max-width: 800px) {
  .product {
    .productBanner {
      padding-top: 200px;
      .left{
   
      img {
      width: 100%;
      height: auto;
    }
    }
    }
    .characteristic .WebMain .img {
      width: 100%;
      .item1, .item2, .item3{
        position: relative;
        // display: flex;
        width: 100%;
        right: 0;
        top: 0;
        .left, .right{
          width: 50%;
        }
        p{
          text-align: center !important;
        }
      }
      // .item1{
      //   top: 0px;
      // }
      // .item2{
      //   top: 100px;
      // }
    }
     .explain .WebMain{
      justify-content: center;
      .item{
        margin-top: 30px;
        .top{
          opacity: 0;
        }
        .all{
          background-position: 0 0 !important;
          background-color: #2B2D33 !important;
          .inner{
            top: 0;
          }
        }
      }
    }
  }
}
</style>